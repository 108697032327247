<template>
  <div>
    <div
      :disabled="disabled"
      @click.prevent="showMoreVisible ? (showMoreBtnVisible = !showMoreBtnVisible) : false"
      :class="['dg-copyContainer', { 'dg-copyContainer--sm': showMoreVisible }]"
    >
      {{ localStr }}
      <template v-if="showMoreVisible">
        <span v-if="showMoreBtnVisible" class="dg-copyContainer__text"
          >{{ showText.more }}<img class="dg-copyContainer__icon" src="../../images/down-chevron.svg"
        /></span>
        <span v-else class="dg-copyContainer__text"
          >{{ showText.less
          }}<img class="dg-copyContainer__icon dg-copyContainer__icon--180" src="../../images/down-chevron.svg"
        /></span>
      </template>
    </div>
    <button v-on:click="copyPluginCode(str)" class="dg-copyContainer__btn">
      <img src="../../images/copy-24.svg" />
    </button>
  </div>
</template>
<script>
export default {
  name: "DSRCopyContainer",
  data() {
    return {
      showMoreBtnVisible: true,
    };
  },
  computed: {
    showMoreVisible() {
      return this.str.length > this.maxChar;
    },
    localStr() {
      if (this.showMoreVisible && this.showMoreBtnVisible) {
        return this.str.substring(1, this.maxChar) + "...";
      }
      return this.str;
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    str: {
      type: String,
      required: true,
    },
    maxChar: {
      type: Number,
      default: 300,
    },
    showText: {
      type: Object,
      default() {
        return {
          more: this.$t("dsr.settings.pp.expand_button"),
          less: this.$t("dsr.settings.pp.less_button"),
        };
      },
      validator: val => "more" in val && "less" in val,
    },
  },
  methods: {
    copyPluginCode(str) {
      this.$clipboard(str);
      this.$dgToast.show({
        type: "info",
        title: "Copied",
        content: "Copied Code to your clipboard",
        closeTimeout: 1500,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-copyContainer {
  resize: none;
  border: 1px dashed #9aa7b0;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 24px;
  padding: 9px 64px 9px 14px;
  color: #0b344b;
  width: 100%;
  -webkit-transition: 0.2s all ease;
  transition: 0.2s all ease;
  background: #f1f1f1;
  word-break: break-all;
  position: relative;
  cursor: pointer;

  &--sm {
    padding-bottom: 30px;
  }

  &__text {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #7e93a7;
    margin-right: 8px;
    margin-bottom: 3px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    .dg-copyContainer__icon {
      width: 15px !important;
      margin-left: 3px;

      &--180 {
        transform: rotate(180deg);
      }
    }
  }

  &__btn {
    border: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: absolute;
    background: transparent;
    color: transparent;
    // width: 64px;
    height: 43px;
    right: 0;
    top: 0;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    padding: 9px;
  }
}
</style>
